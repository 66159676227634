var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"border-title align-items-center justify-content-center mx-1 my-3",staticStyle:{"background-color":"white","color":"#828c9c","height":"auto"}},[_c('CRow',{staticClass:"align-items-center"},[_c('CCol',{staticClass:"align-items-center",attrs:{"sm":"6","xl":"2"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12","xl":"12"}},[_c('b',[_vm._v(_vm._s(`${_vm.$t('label.movements')} / ${_vm.$t('label.dispatch')}`))])])],1)],1),_c('CCol',{attrs:{"sm":"6","xl":"2"}},[_c('CRow',[_c('CCol',{staticClass:"align-items-center",attrs:{"sm":"12","xl":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.supplie'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.supplie'),\n                placement: 'top-end'\n              }"}],staticClass:"d-flex align-items-center",attrs:{"shape":"square","color":"add"},on:{"click":function($event){return _vm.toggleAdd({})}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1)],1)],1)],1),_c('div',[_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"number",fn:function({ index }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"status",fn:function({item}){return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"edit",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.yard'),
                                placement: 'top-end'
                            }),expression:"{\n                                content: $t('label.yard'),\n                                placement: 'top-end'\n                            }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}},{key:"seals",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.Seals'),
                                placement: 'top-end'
                            }),expression:"{\n                                content: $t('label.Seals'),\n                                placement: 'top-end'\n                            }"}],staticClass:"mr-1",staticStyle:{"background":"#e0e0e0"},attrs:{"square":"","size":"sm"},on:{"click":function($event){return _vm.viewSealsDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}})],1)],1)]}},{key:"documentation",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('DOCUMENTACIÓN'),
                                placement: 'top-end'
                            }),expression:"{\n                                content: $t('DOCUMENTACIÓN'),\n                                placement: 'top-end'\n                            }"}],staticClass:"mr-1",staticStyle:{"background":"#e0e0e0"},attrs:{"square":"","size":"sm"},on:{"click":function($event){return _vm.viewDocumentationDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}})],1)],1)]}},{key:"logistic",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.logistics'),
                                placement: 'top-end'
                            }),expression:"{\n                                content: $t('label.logistics'),\n                                placement: 'top-end'\n                            }"}],staticClass:"mr-1",staticStyle:{"background":"#e0e0e0"},attrs:{"square":"","size":"sm"},on:{"click":function($event){return _vm.viewLogisticDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}},{key:"details",fn:function({item}){return [_c('CCollapse',{attrs:{"show":Boolean(item._toggledSeals),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('dataTableExtended',{staticClass:"align-center-row-datatable table-lv-2",attrs:{"small":"","items":_vm.formatedSealsItems,"fields":_vm.sealsFields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage},scopedSlots:_vm._u([{key:"view",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                      content: _vm.$t('label.yard'),
                                      placement: 'top-end'
                                  }),expression:"{\n                                      content: $t('label.yard'),\n                                      placement: 'top-end'\n                                  }"}],staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.viewSealsDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}},{key:"galery",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                      content: _vm.$t('label.yard'),
                                      placement: 'top-end'
                                  }),expression:"{\n                                      content: $t('label.yard'),\n                                      placement: 'top-end'\n                                  }"}],staticClass:"mr-1",attrs:{"color":"add","square":"","size":"sm"},on:{"click":function($event){return _vm.viewSealsDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-image"}})],1)],1)]}}],null,true)})],1)],1),_c('CCollapse',{attrs:{"show":Boolean(item._toggledDocumentation),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('dataTableExtended',{staticClass:"align-center-row-datatable table-lv-2",attrs:{"small":"","items":_vm.formatedDocumentationItems,"fields":_vm.documentationFields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage},scopedSlots:_vm._u([{key:"view",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                      content: _vm.$t('label.yard'),
                                      placement: 'top-end'
                                  }),expression:"{\n                                      content: $t('label.yard'),\n                                      placement: 'top-end'\n                                  }"}],staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.viewSealsDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}},{key:"galery",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                      content: _vm.$t('label.yard'),
                                      placement: 'top-end'
                                  }),expression:"{\n                                      content: $t('label.yard'),\n                                      placement: 'top-end'\n                                  }"}],staticClass:"mr-1",attrs:{"color":"add","square":"","size":"sm"},on:{"click":function($event){return _vm.viewSealsDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1)],1)]}}],null,true)})],1)],1),_c('CCollapse',{attrs:{"show":Boolean(item._toggledLogistic),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('dataTableExtended',{staticClass:"align-center-row-datatable table-lv-2",attrs:{"small":"","items":_vm.formatedLogisticItems,"fields":_vm.logisticFields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage},scopedSlots:_vm._u([{key:"view",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                      content: _vm.$t('label.yard'),
                                      placement: 'top-end'
                                  }),expression:"{\n                                      content: $t('label.yard'),\n                                      placement: 'top-end'\n                                  }"}],staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.viewSealsDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}},{key:"galery",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                      content: _vm.$t('label.yard'),
                                      placement: 'top-end'
                                  }),expression:"{\n                                      content: $t('label.yard'),\n                                      placement: 'top-end'\n                                  }"}],staticClass:"mr-1",attrs:{"color":"add","square":"","size":"sm"},on:{"click":function($event){return _vm.viewSealsDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1)],1)]}}],null,true)})],1)],1)]}}])})],1)],1)],1),_c('ModalRegister',{attrs:{"modal":_vm.ModalContainer,"ContainerItem":_vm.ContainerItem},on:{"update:modal":function($event){_vm.ModalContainer=$event},"Update-list":_vm.Update}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }