<template>
  <div>
    <div class="border-title align-items-center justify-content-center mx-1 my-3" style="background-color: white; color: #828c9c; height: auto;">
      <CRow class="align-items-center">
        <CCol sm="6" xl="2" class="align-items-center">
          <CRow>
            <CCol sm="12" xl="12">
              <b>{{`${$t('label.movements')} / ${$t('label.dispatch')}`}}</b>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="6" xl="2" >
          <CRow>
            <CCol sm="12" xl="12" class="align-items-center">
              <CButton
                shape="square"
                color="add"
                class="d-flex align-items-center"
                v-c-tooltip="{
                  content: $t('label.supplie'),
                  placement: 'top-end'
                }"
                @click="toggleAdd({})"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </div>
    <div>
      <CRow>
          <CCol col="12" xl="12">
              <dataTableExtended
                  class="align-center-row-datatable"
                  hover
                  sorter
                  small
                  details
                  column-filter
                  :items="formatedItems"
                  :fields="fields"
                  :noItemsView="tableText.noItemsViewText"
                  :items-per-page="tableText.itemsPerPage"
                  pagination
              >
                  <template #number="{ index }">
                      <td class="text-center">
                          {{index+1}}
                      </td>
                  </template>
                  <template #status="{item}">
                    <td>
                      <CBadge :color="getBadge(item.status)">{{item.status}}</CBadge>
                    </td>
                  </template>
                  <template #edit="{item, index}">
                      <td class="text-center">
                          <CButton
                              color="edit"
                              square
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                  content: $t('label.yard'),
                                  placement: 'top-end'
                              }"
                          >
                            <CIcon name="pencil" />
                          </CButton>
                      </td>
                  </template>
                  <template #seals="{item, index}">
                      <td class="text-center">
                          <CButton
                              square
                              style="background:#e0e0e0"
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                  content: $t('label.Seals'),
                                  placement: 'top-end'
                              }"
                              @click="viewSealsDetail(item, index)"
                          >
                              <CIcon name="cil-file-excel"/>
                          </CButton>
                      </td>
                  </template>
                  <template #documentation="{item, index}">
                      <td class="text-center">
                          <CButton
                              square
                              style="background:#e0e0e0"
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                  content: $t('DOCUMENTACIÓN'),
                                  placement: 'top-end'
                              }"
                              @click="viewDocumentationDetail(item, index)"
                          >
                              <CIcon name="cil-file-pdf"/>
                          </CButton>
                      </td>
                  </template>
                  <template #logistic="{item, index}">
                      <td class="text-center">
                          <CButton
                              square
                              style="background:#e0e0e0"
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                  content: $t('label.logistics'),
                                  placement: 'top-end'
                              }"
                              @click="viewLogisticDetail(item, index)"
                          >
                              <CIcon name="eye"/>
                          </CButton>
                      </td>
                  </template>
                  <template #details="{item}">
                    <CCollapse
                      :show="Boolean(item._toggledSeals)"
                      :duration="collapseDuration"
                    >
                      <CCardBody>
                        <dataTableExtended
                          class="align-center-row-datatable table-lv-2"
                          small
                          :items="formatedSealsItems"
                          :fields="sealsFields"
                          :noItemsView="tableText.noItemsViewText"
                          :items-per-page="tableText.itemsPerPage"
                      >
                        <template #view="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="watch"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.yard'),
                                        placement: 'top-end'
                                    }"
                                    @click="viewSealsDetail(item, index)"
                                >
                                    <CIcon name="eye"/>
                                </CButton>
                            </td>
                        </template>
                        <template #galery="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="add"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.yard'),
                                        placement: 'top-end'
                                    }"
                                    @click="viewSealsDetail(item, index)"
                                >
                                    <CIcon name="cil-image"/>
                                </CButton>
                            </td>
                        </template>
                      </dataTableExtended>
                      </CCardBody>
                    </CCollapse>

                    <CCollapse
                      :show="Boolean(item._toggledDocumentation)"
                      :duration="collapseDuration"
                    >
                      <CCardBody>
                        <dataTableExtended
                          class="align-center-row-datatable table-lv-2"
                          small
                          :items="formatedDocumentationItems"
                          :fields="documentationFields"
                          :noItemsView="tableText.noItemsViewText"
                          :items-per-page="tableText.itemsPerPage"
                      >
                        <template #view="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="watch"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.yard'),
                                        placement: 'top-end'
                                    }"
                                    @click="viewSealsDetail(item, index)"
                                >
                                    <CIcon name="eye"/>
                                </CButton>
                            </td>
                        </template>
                        <template #galery="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="add"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.yard'),
                                        placement: 'top-end'
                                    }"
                                    @click="viewSealsDetail(item, index)"
                                >
                                    <CIcon name="cil-cloud-download"/>
                                </CButton>
                            </td>
                        </template>
                      </dataTableExtended>
                      </CCardBody>
                    </CCollapse>

                    <CCollapse
                      :show="Boolean(item._toggledLogistic)"
                      :duration="collapseDuration"
                    >
                      <CCardBody>
                        <dataTableExtended
                          class="align-center-row-datatable table-lv-2"
                          small
                          :items="formatedLogisticItems"
                          :fields="logisticFields"
                          :noItemsView="tableText.noItemsViewText"
                          :items-per-page="tableText.itemsPerPage"
                      >
                        <template #view="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="watch"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.yard'),
                                        placement: 'top-end'
                                    }"
                                    @click="viewSealsDetail(item, index)"
                                >
                                    <CIcon name="eye"/>
                                </CButton>
                            </td>
                        </template>
                        <template #galery="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="add"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.yard'),
                                        placement: 'top-end'
                                    }"
                                    @click="viewSealsDetail(item, index)"
                                >
                                    <CIcon name="cil-cloud-download"/>
                                </CButton>
                            </td>
                        </template>
                      </dataTableExtended>
                      </CCardBody>
                    </CCollapse>
                  </template>
              </dataTableExtended>
          </CCol>
      </CRow>
    </div>
    <ModalRegister
      :modal.sync="ModalContainer"
      :ContainerItem="ContainerItem"
      @Update-list="Update"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import ModalRegister from './modal-register';

//DATA-------------------------
function data() {
  return {
    collapseDuration: 0,
    _toggledSeals: false,
    _toggledDocumentation: false,
    _toggledLogistic: false,
    ModalContainer: false,
    ContainerItem:{},
    dataDispatch :
    [
      {
        number:'1',
        patio: 'sdfsd',
        device: 'sdfds',
        carrier: 'dsfdsf',
        vehicle:'sdfsdf',
        pase: 'asdff',
        eir: 'true',
        sidunea: 'true',
        due: 'true',
        dateDispath: 'true',
        user: 'true',
        dateRegister: 'true',
        status: 'true',
        _toggledSeals: false,
        _toggledDocumentation: false,
        _toggledLogistic: false,
      },
      {
        number:'2',
        patio: 'sdfsd',
        device: 'sdfds',
        carrier: 'dsfdsf',
        vehicle:'sdfsdf',
        pase: 'asdff',
        eir: 'true',
        sidunea: 'true',
        due: 'true',
        dateDispath: 'true',
        user: 'true',
        dateRegister: 'true',
        status: 'true',
        _toggledSeals: false,
        _toggledDocumentation: false,
        _toggledLogistic: false,
      },

    ],
    dataSeals :
    [
      {
        seals:'1',
        condition: 'sdfsd',
        after: 'sdfds',
        new: 'dsfdsf',
        _classes: 'value-Table' ,
      },
      {
        seals:'2',
        condition: 'sdfsd',
        after: 'sdfds',
        new: 'dsfdsf',
        _classes: 'value-Table' ,
      },
      {
        seals:'3',
        condition: 'sdfsd',
        after: 'sdfds',
        new: 'dsfdsf',
        _classes: 'value-Table' ,
      }
    ],
    dataDocumentation :
    [
      {
        eir: '....',
        sidunea: '....',
        due: '....',
        exit: '....',
      }
    ],
    dataLogistic :
    [
      {
        maquinary:'....',
        carrier:'.....',
        driver:'....',
        vehicle:'.....',
      }
    ],
  }
}

function Update() {
  this.ModalContainer = false;
}

function formatedItems() {
  return this.dataDispatch.filter(item => {
      return item
  })
}

function formatedSealsItems() {
  return this.dataSeals.filter(item => {
      return item
  })
}

function formatedDocumentationItems() {
  return this.dataDocumentation.filter(item => {
      return item
  })
}

function formatedLogisticItems() {
  return this.dataLogistic.filter(item => {
      return item
  })
}


function fields(){
        return [
          {
            key: 'edit', 
            label: '', 
            _style: 'min-width: 5%; text-align:center;', 
            sorter: false, 
            filter: false
          },
          { 
            key: 'seals', 
            label: '', 
            _style: 'min-width: 5%; text-align:center;', 
            sorter: false, 
            filter: false
          },
          { 
            key: 'documentation', 
            label: '', 
            _style: 'min-width: 5%; text-align:center;', 
            sorter: false, 
            filter: false
          },
          { 
            key: 'logistic', 
            label: '', 
            _style: 'min-width: 5%; text-align:center;', 
            sorter: false, 
            filter: false
          },
          { key: 'number', label: '#',_style: 'min-width: 40px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
          { key: 'patio',label: this.$t('label.yard'), _style:'min-width: 70px; text-align:center;', _classes: 'text-uppercase text-center'},
          { key: 'device', label: this.$t('label.device'), _style:'min-width: 100px; text-align:center;', _classes: 'text-uppercase text-center'},
          { key: 'carrier', label: this.$t('label.transport'), _style:'min-width: 120px; text-align:center;', _classes: 'text-uppercase text-center' },
          { key: 'vehicle', label: this.$t('label.vehicle'), _style:'min-width: 80px; text-align:center;', _classes:"text-center"},
          { key: 'pase', label: this.$t('label.ticketOut'),  _style:'min-width: 120px; text-align:center;', _classes:"text-center" },
          { key: 'eir', label: this.$t('label.eir'),  _style:'min-width: 110px; text-align:center;', _classes:"text-center" },
          { key: 'sidunea', label: this.$t('label.sidunea'),  _style:'min-width: 100px; text-align:center;', _classes:"text-center" },
          { key: 'due', label: this.$t('label.dua'),  _style:'min-width: 90px; text-align:center;', _classes:"text-center" },
          { key: 'dateDispath', label: this.$t('label.dispatchDate'),  _style:'min-width: 150px; text-align:center;', _classes:"text-center" },
          { key: 'user', label: this.$t('label.user'),  _style:'min-width: 120px; text-align:center;', _classes:"text-center" },
          { key: 'dateRegister', label: this.$t('label.registerDate'),  _style:'min-width: 150px; text-align:center;', _classes:"text-center" },
          { key: 'status', label: this.$t('label.status'),  _style:'min-width: 100px; text-align:center;', _classes:"text-center" },
          
        ];
    }

  function sealsFields(){
      return [
          {
            key: 'view', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { 
            key: 'galery', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { key: 'seals',label: this.$t('label.Seals'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          { key: 'condition', label: this.$t('label.condition'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          { key: 'after', label: this.$t('label.previous'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          { key: 'new', label: this.$t('label.nuevo'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
          
      ];
  }
  

  function f(){
      return [
          { key: 'eir',label: this.$t('label.eir'), _style:'width: 15%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          {
            key: 'view', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { 
            key: 'galery', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { key: 'sidunea', label: this.$t('label.sidunea'), _style:'width: 15%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          {
            key: 'view', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { 
            key: 'galery', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { key: 'due', label: this.$t('label.dua'), _style:'width: 10%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          {
            key: 'view', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { 
            key: 'galery', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { key: 'exit', label: this.$t('label.ticketOut'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
          {
            key: 'view', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { 
            key: 'galery', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
      ];
  }

  function logisticFields(){
      return [
          { key: 'maquinary',label: this.$t('label.machine'), _style:'width: 15%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          { key: 'carrier', label: this.$t('label.transport'), _style:'width: 15%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          { key: 'driver', label: this.$t('label.driver'), _style:'width: 10%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          { key: 'vehicle', label: this.$t('label.vehicle'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
      ];
  }


  function closeShow(item, level) {

  }

  function viewSealsDetail(item){
    this.closeShow(item, 1);

    item._toggledSeals = !item._toggledSeals;
    item._toggledDocumentation = false;
    item._toggledLogistic = false;
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
      this.loading = false;
      this.apiStateLoading = false;
    });
  }

  function viewDocumentationDetail(item){
    this.closeShow(item, 1);

    item._toggledSeals = false;
    item._toggledDocumentation = !item._toggledDocumentation;
    item._toggledLogistic = false;
    
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
      this.loading = false;
      this.apiStateLoading = false;
    });
  }

  function viewLogisticDetail(item){
    this.closeShow(item, 1);

    item._toggledSeals = false;
    item._toggledDocumentation = false;
    item._toggledLogistic = !item._toggledLogistic;
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
      this.loading = false;
      this.apiStateLoading = false;
    });
  }


//computed
function VisitOptions(){
  if(this.ListVisit.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ListVisit.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: e.IncidenceGpoNameEn,
      })    
    })
    return chart;
  }
}

function toggleAdd(item) {
    this.ContainerItem = item;
    this.ModalContainer = !this.ModalContainer;
}


export default {
  name: 'dispatch',
  components: {
    CustomTabs,
    CustomTab,
    ModalRegister
   },
  data,
  mixins: [GeneralMixin],
  methods: {
    viewSealsDetail,
    viewDocumentationDetail,
    viewLogisticDetail,
    closeShow,
    Update,
    toggleAdd
  },
  computed: {
    VisitOptions,
    fields,
    sealsFields,
    documentationFields,
    logisticFields,
    formatedItems,
    formatedSealsItems,
    formatedDocumentationItems,
    formatedLogisticItems
  },
};
</script>
<style scoped>
.Containers-header{
  background: #4e5a70;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.Containers-Search{
  background: #ffffff;
  border-radius: 10px;
}
.form-control-Search{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #fff;
  border-radius: 10px;
  outline: none;
}
.Containers-data{
  margin: 5px;
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 10px;
}

.Container-items{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
</style>